var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("takeaway-meals-requests-table", {
        attrs: { reloadOrders: _vm.reloadOrders },
        on: {
          selectedOrders: _vm.selectedOrders,
          reloadOrdersDone: function($event) {
            _vm.reloadOrders = $event
          }
        }
      }),
      _c("SideButtons", {
        attrs: {
          reset: _vm.resetForm,
          canEdit:
            _vm.canEdit || _vm.dotationRequestForm.statusPlain != "draft",
          select: _vm.have,
          save: _vm.send,
          ODS: false,
          service_order: false
        },
        on: {
          info: function($event) {
            _vm.info = $event
          },
          add: function($event) {
            _vm.add = $event
          },
          save: function($event) {
            _vm.save = $event
          },
          saveToPending: function($event) {
            _vm.saveToPending = $event
          },
          edit: function($event) {
            _vm.edit = $event
          },
          SaveEdit: function($event) {
            _vm.SaveEdit = $event
          },
          confirm: function($event) {
            _vm.confirm = $event
          },
          reject: function($event) {
            _vm.reject = $event
          },
          validate: function($event) {
            _vm.validate = $event
          },
          resetEmit: function($event) {
            _vm.resetForm = $event
          },
          delete: function($event) {
            _vm.destroy = $event
          }
        }
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showForm,
              expression: "showForm"
            }
          ],
          staticClass: "row"
        },
        [
          _c("div", { staticClass: "col-lg-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c(
                  "form",
                  {
                    staticClass: "needs-validation",
                    attrs: { enctype: "multipart/form-data" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.formSubmit($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-md-4 col-lg-6" },
                        [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "formrow-password-input" } },
                              [_vm._v("Motif de la demande *")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.dotationRequestForm.reason,
                                  expression: "dotationRequestForm.reason"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.dotationRequestForm.reason
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.dotationRequestForm,
                                    "reason",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-md-4 col-lg-3" },
                        [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "formrow-inputCity" } },
                              [_vm._v("Date *")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.dotationRequestForm.date,
                                  expression: "dotationRequestForm.date"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "date", name: "", id: "" },
                              domProps: { value: _vm.dotationRequestForm.date },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.dotationRequestForm,
                                    "date",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-md-4 col-lg-3" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formrow-inputCity" } },
                                [_vm._v("Destination *")]
                              ),
                              _c("multiselect", {
                                attrs: {
                                  searchable: true,
                                  "track-by": "id",
                                  label: "name",
                                  options: _vm.destinationTypes,
                                  placeholder: "Select user structure",
                                  "allow-empty": false,
                                  "select-label": "",
                                  "deselect-label": ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "singleType",
                                    fn: function(ref) {
                                      var type = ref.type
                                      return [_vm._v(_vm._s(type.name))]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.dotationRequestForm.location_type,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.dotationRequestForm,
                                      "location_type",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dotationRequestForm.location_type"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-md-4 col-lg-6" },
                        [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Imputation *")
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.dotationRequestForm.analyticAccount,
                                  expression:
                                    "dotationRequestForm.analyticAccount"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.dotationRequestForm.analyticAccount
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.dotationRequestForm,
                                    "analyticAccount",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]
                      ),
                      _vm.dotationRequestForm.location_type.id == "external"
                        ? _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-4 col-lg-3" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "formrow-inputCity" } },
                                    [_vm._v("Destination externe *")]
                                  ),
                                  _c("multiselect", {
                                    attrs: {
                                      searchable: true,
                                      "track-by": "id",
                                      label: "name",
                                      options: _vm.externalDestinations,
                                      placeholder: "Select user structure",
                                      "allow-empty": false,
                                      "select-label": "",
                                      "deselect-label": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "singleDestination",
                                          fn: function(ref) {
                                            var destination = ref.destination
                                            return [
                                              _vm._v(_vm._s(destination.name))
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      900783445
                                    ),
                                    model: {
                                      value:
                                        _vm.dotationRequestForm
                                          .externalDestination,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dotationRequestForm,
                                          "externalDestination",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dotationRequestForm.externalDestination"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.dotationRequestForm.location_type.id == "external"
                        ? _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-4 col-lg-3" },
                            [
                              _c("div", { staticClass: "form-grou" }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Détails de Destination")
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.dotationRequestForm
                                          .externalDestinationTxt,
                                      expression:
                                        "dotationRequestForm.externalDestinationTxt"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: {
                                    value:
                                      _vm.dotationRequestForm
                                        .externalDestinationTxt
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.dotationRequestForm,
                                        "externalDestinationTxt",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.dotationRequestForm.location_type.id == "internal"
                        ? _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-4 col-lg-3" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "formrow-inputCity" } },
                                    [_vm._v("Type de structure *")]
                                  ),
                                  _c("multiselect", {
                                    attrs: {
                                      searchable: true,
                                      "track-by": "id",
                                      label: "name",
                                      options: _vm.structureTypes,
                                      placeholder: "Select user structure",
                                      "allow-empty": false,
                                      "select-label": "",
                                      "deselect-label": ""
                                    },
                                    on: { input: _vm.onchangeStructureType },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "singleStruct",
                                          fn: function(ref) {
                                            var type = ref.type
                                            return [_vm._v(_vm._s(type.name))]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3909628126
                                    ),
                                    model: {
                                      value:
                                        _vm.dotationRequestForm.structure_type,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dotationRequestForm,
                                          "structure_type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dotationRequestForm.structure_type"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.dotationRequestForm.location_type.id == "internal"
                        ? _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-4 col-lg-3" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      attrs: { for: "formrow-password-input" }
                                    },
                                    [_vm._v("Structure *")]
                                  ),
                                  _c("multiselect", {
                                    attrs: {
                                      searchable: true,
                                      "track-by": "id",
                                      label: "name",
                                      options: _vm.structures,
                                      placeholder: "Select user structure",
                                      "allow-empty": false,
                                      "select-label": "",
                                      "deselect-label": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "singleStruct",
                                          fn: function(ref) {
                                            var struct = ref.struct
                                            return [_vm._v(_vm._s(struct.name))]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      4101083870
                                    ),
                                    model: {
                                      value: _vm.dotationRequestForm.structure,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dotationRequestForm,
                                          "structure",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dotationRequestForm.structure"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ]),
                    _c("hr"),
                    _c(
                      "div",
                      [
                        _vm._l(_vm.dotationRequestForm.products, function(
                          requestMeals,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: requestMeals.id, staticClass: "row" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group col-lg-4" },
                                [
                                  _c("label", { attrs: { for: "resume" } }, [
                                    _vm._v("Produit")
                                  ]),
                                  _c("multiselect", {
                                    attrs: {
                                      searchable: true,
                                      "track-by": "id",
                                      label: "name",
                                      options: _vm.products,
                                      placeholder: "Selectionner un produit",
                                      "allow-empty": false,
                                      "select-label": "",
                                      "deselect-label": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "singleMealType",
                                          fn: function(ref) {
                                            var mealType = ref.mealType
                                            return [
                                              _vm._v(_vm._s(mealType.name))
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: requestMeals.product,
                                      callback: function($$v) {
                                        _vm.$set(requestMeals, "product", $$v)
                                      },
                                      expression: "requestMeals.product"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-lg-3" },
                                [
                                  _c("label", { attrs: { for: "subject" } }, [
                                    _vm._v("Qty")
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: requestMeals.qty,
                                        expression: "requestMeals.qty"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "subject",
                                      type: "number",
                                      min: "1"
                                    },
                                    domProps: { value: requestMeals.qty },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          requestMeals,
                                          "qty",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-lg-4" },
                                [
                                  _c("label", { attrs: { for: "message" } }, [
                                    _vm._v("Observations")
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: requestMeals.description,
                                        expression: "requestMeals.description"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { id: "subject", type: "text" },
                                    domProps: {
                                      value: requestMeals.description
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          requestMeals,
                                          "description",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "col-lg-1 align-self-center" },
                                [
                                  _vm.dotationRequestForm.products.length > 1 &&
                                  index != 0
                                    ? _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:void(0)" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "bx bx-trash-alt",
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteRow(index)
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        }),
                        _c("input", {
                          staticClass: "btn btn-success mt-3 mt-lg-0",
                          attrs: { type: "button", value: "Ajouter" },
                          on: { click: _vm.AddformData }
                        })
                      ],
                      2
                    ),
                    _c("br"),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "formrow-inputCity" } }, [
                            _vm._v("Commentaires")
                          ]),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.dotationRequestForm.notes,
                                expression: "dotationRequestForm.notes"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { cols: "30", rows: "4" },
                            domProps: { value: _vm.dotationRequestForm.notes },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.dotationRequestForm,
                                  "notes",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]),
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-light btn-label float-right ml-2",
                          attrs: { type: "button" },
                          on: { click: _vm.cancelBtn }
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-times text-white label-icon"
                          }),
                          _vm._v(" Annuler ")
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-label btn-primary float-right",
                          attrs: {
                            type: "submit",
                            disabled: _vm.$v.dotationRequestForm.$invalid
                          }
                        },
                        [
                          _c("i", { staticClass: "far fa-save label-icon " }),
                          _vm._v(" Enregistrer ")
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showForm,
              expression: "!showForm"
            }
          ]
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xl-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h4", { staticClass: "card-title mb-4" }, [
                    _vm._v("Détails "),
                    _vm.dotationRequestForm.statusPlain == "draft" &&
                    _vm.$can("edit_vsr")
                      ? _c(
                          "div",
                          { staticClass: "button-items float-right mr-5" },
                          [
                            _vm.dotationRequestForm.statusPlain == "draft" &&
                            _vm.$can("approve_vsr")
                              ? _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.approvalModal",
                                        modifiers: { approvalModal: true }
                                      }
                                    ],
                                    staticClass: "btn-label",
                                    attrs: { variant: "success" }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bx bx-check-double font-size-16 align-middle mr-2 text-white label-icon"
                                    }),
                                    _vm._v(" Approuver ")
                                  ]
                                )
                              : _vm._e(),
                            _vm.dotationRequestForm.statusPlain == "draft" &&
                            _vm.$can("reject_vsr")
                              ? _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.rejectionModal",
                                        modifiers: { rejectionModal: true }
                                      }
                                    ],
                                    staticClass: "btn-label",
                                    attrs: { variant: "danger" }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bx bx-block font-size-16 align-middle mr-2 text-white label-icon"
                                    }),
                                    _vm._v(" Rejeter ")
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c("p", { attrs: { lass: "text-muted mb-4" } }),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "table-responsive mb-0" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("Demandeur :")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.dotationRequestForm.requestedBy)
                                )
                              ])
                            ]),
                            _vm.dotationRequestForm.location_type.id ==
                            "external"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Destination externe :")]),
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.dotationRequestForm
                                            .externalDestination["name"]
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.dotationRequestForm.location_type.id ==
                            "external"
                              ? _c("tr", [
                                  _c("th", [
                                    _vm._v("Détails de Destination :")
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.dotationRequestForm
                                            .externalDestinationTxt
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.dotationRequestForm.location_type.id ==
                            "internal"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Type de structure :")]),
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.dotationRequestForm
                                            .structure_type["name"]
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.dotationRequestForm.location_type.id ==
                            "internal"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Structure :")]),
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.dotationRequestForm.structure[
                                            "name"
                                          ]
                                        ) +
                                        " "
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _c("tr", [
                              _c("th", [_vm._v("Imputation :")]),
                              _c("td", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dotationRequestForm.analyticAccount
                                    )
                                  )
                                ])
                              ])
                            ]),
                            _vm.dotationRequestForm.statusPlain == "rejected"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Rejeté par :")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.dotationRequestForm.rejectedBy)
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.dotationRequestForm.statusPlain == "approved"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Approuvée par :")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.dotationRequestForm.approvedBy)
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "table-responsive mb-0" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("Destination :")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.dotationRequestForm.location_type[
                                      "name"
                                    ]
                                  )
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Date de demande :")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.dotationRequestForm.date))
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Motif de la demande :")]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.dotationRequestForm.reason) +
                                    " "
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Etat :")]),
                              _c("td", [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.dotationRequestForm.status
                                    )
                                  }
                                })
                              ])
                            ]),
                            _vm.dotationRequestForm.statusPlain == "rejected"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Date de rejet:")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dotationRequestForm.rejectionDate
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.dotationRequestForm.statusPlain == "rejected"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Raison de rejet:")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dotationRequestForm.rejectionReason
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.dotationRequestForm.statusPlain == "approved"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Approuvée Le :")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.dotationRequestForm.approvedAt)
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.dotationRequestForm.statusPlain == "approved" &&
                            _vm.dotationRequestForm.reception.length > 0
                              ? _c("tr", [
                                  _c("th", [_vm._v("Bon de Livraison :")]),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "ops.receptions.display",
                                              params: {
                                                uid:
                                                  _vm.dotationRequestForm
                                                    .reception.uuid
                                              }
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dotationRequestForm.reception
                                                .ref
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-12" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v("Commentaires : ")
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _c("p", { staticClass: "card-title-desc" }, [
                        _vm._v(_vm._s(_vm.dotationRequestForm.notes))
                      ]),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c(
                          "table",
                          { staticClass: "table table-striped mb-0" },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [_vm._v("Produit")]),
                                _c("th", [_vm._v("Qty")]),
                                _c("th", [_vm._v("Observations")])
                              ])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.dotationRequestForm.products, function(
                                entry
                              ) {
                                return _c("tr", { key: entry.id }, [
                                  _c("td", [
                                    _vm._v(_vm._s(entry.display_name))
                                  ]),
                                  _c("td", [_vm._v(_vm._s(entry.qty) + " ")]),
                                  _c("td", [_vm._v(_vm._s(entry.description))])
                                ])
                              }),
                              0
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _c("approval-modal", {
            attrs: {
              requestData: _vm.dotationRequestForm,
              modelUid: _vm.dotationRequestForm.uuid,
              modelRef: _vm.dotationRequestForm.ref
            }
          }),
          _c("rejection-modal", {
            attrs: {
              modelRef: _vm.dotationRequestForm.ref,
              modelId: _vm.dotationRequestForm.id,
              modelType: _vm.dotationRequestForm.modelType
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }